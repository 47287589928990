<template>
	<div>
		<Header :title="isEdit ? '修改抖音券等级' : '添加抖音券等级'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
		<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 4, xxl: 3}"
			:wrapperCol="{span: 16, xxl: 15 }">

			<a-form-item label="影院组织" name="organizationId" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-select :disabled="isSee || isEdit" v-model:value="modelRef.organizationId" placeholder="请选择影院组织" show-search :filter-option="filterOption" @change="getCinemaList">
					<a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
				</a-select>
			</a-form-item>

			<a-form-item label="所属影院" name="cinemaId" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-select :disabled="isSee || isEdit" v-model:value="modelRef.cinemaId" placeholder="请选择所属影院" show-search :filter-option="filterOption">
					<a-select-option v-for="item in cinemaAllList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
				</a-select>
			</a-form-item>

			<a-form-item label="抖音商品名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input :disabled="isSee" v-model:value="modelRef.title" placeholder="请输入等级名称"></a-input>
			</a-form-item>

			<a-form-item label="商品sku" name="goodsSku" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input :disabled="isSee" v-model:value="modelRef.goodsSku" placeholder="请输入商品sku"></a-input>
			</a-form-item>

			<a-form-item label="有效期" :rules="[{required: true, message: '必填项不允许为空'}]">
				<div style="display: flex;">
					<a-input-number :disabled="isSee" v-model:value="modelRef.validityValue" v-if="[2,3,4].includes(modelRef.validityType)" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					<a-select :disabled="isSee" style="width: 180px;" v-model:value="modelRef.validityType" placeholder="请选择" @change="modelRef.validityValue = null">
						<a-select-option :value="1">永久有效</a-select-option>
						<a-select-option :value="2">年</a-select-option>
						<a-select-option :value="3">月</a-select-option>
						<a-select-option :value="4">日</a-select-option>
						<a-select-option :value="5">指定时间</a-select-option>
					</a-select>
				</div>
			</a-form-item>

			<a-form-item v-if="modelRef.validityType === 5" label="指定时间" name="validityValue" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-date-picker :disabled="isSee" v-model:value="modelRef.validityValue" />
			</a-form-item>

			<a-form-item label="抖音券类型" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-radio-group v-model:value="modelRef.tiktokTicketType" :disabled="isSee">
					<a-radio :value="1">团购券</a-radio>
					<a-radio :value="2" disabled>代金券</a-radio>
					<a-radio :value="3" disabled>次卡</a-radio>
				</a-radio-group>
			</a-form-item>

			<a-form-item label="券兑换座位数" name="redeemNum" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input-number v-model:value="modelRef.redeemNum" :disabled="isSee" :min="0" :precision="0" placeholder="请输入"></a-input-number> 张
			</a-form-item>

			<!-- <a-form-item label="等级名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input :disabled="isSee" v-model:value="modelRef.title" placeholder="请输入等级名称"></a-input>
			</a-form-item> -->

			<a-form-item label="是否含有卖品" name="isHaveSnack" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-radio-group v-model:value="modelRef.isHaveSnack" :disabled="isSee" @change="snackIds=[];snackList=[]">
					<a-radio :value="1">是</a-radio>
					<a-radio :value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>

			<div v-if="modelRef.isHaveSnack">
				<a-form-item label="含有卖品" name="snackIds" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-form-item-rest>
						<a-button v-if="!modelRef.cinemaId" disabled>请选择所属影院</a-button>
						<div v-else>
							<snackSelect v-model:value="modelRef.snackIds" :selectItem="snackList" :id="modelRef.cinemaId" :searchMap="{ isCoupon: 0, isCard: 0 }" @change="(data) => snackList = data"></snackSelect>
						</div>
					</a-form-item-rest>

					<a-form-item-rest>
						<a-table rowKey="id" :dataSource="snackList" :pagination="false"
								 :columns="snackColumns"
								 bordered size="middle"
						>
							<template #bodyCell="{column, record}">
								<template v-if="column.key === 'image'">
									<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
								</template>
								<template v-if="column.key === 'isShelf'">
									{{ record.isShelf === 0 ? '已下架' : '已上架' }}
								</template>
							</template>
						</a-table>
					</a-form-item-rest>
				</a-form-item>
			</div>

			<a-row>
				<a-col :offset="1" :span="20">
					<a-row>
						<!-- <a-col :span="8">
							<a-form-item label="结算金额" name="deductionAmount" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.deductionAmount" :min="0" :precision="2" placeholder="请输入"></a-input-number> 元
							</a-form-item>
						</a-col> -->
						<!-- <a-col :span="8">
							<a-form-item label="限购票数" name="unsettledOrderLimit" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.unsettledOrderLimit" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<a-tooltip>
									<template #title>场次限购票数（单场次限购数, 0 不限购）</template>
									<Icon icon="QuestionCircleOutlined" style="margin-left: 8px;" ></Icon>
								</a-tooltip>
							</a-form-item>
						</a-col> -->
						<a-col :span="8">
							<a-form-item label="是否启用" name="isDisabled" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
						</a-col>
					</a-row>
				</a-col>
			</a-row>

			<!-- <a-row>
				<a-col :offset="1" :span="20">
					<a-row>

						<a-col :span="8">
							<a-form-item label="排序号" name="sort" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
							</a-form-item>
						</a-col>
					</a-row>
				</a-col>
			</a-row> -->

			<a-form-item label="等级描述" name="remarks" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-textarea :disabled="isSee" v-model:value="modelRef.remarks" placeholder="请输入等级描述"></a-textarea>
			</a-form-item>

			<a-row class="ui-header">使用政策</a-row>
			<a-button v-if="!modelRef.cinemaId" disabled>请选择所属影院</a-button>
			<policy v-model:value="policyModel" :disabled="isSee" :id="modelRef.cinemaId" v-else-if="!isSee" type="tiktok" @ok="onPolicyRuleOk"></policy>

			<a-table :columns="columns" :dataSource="modelRef.cardPolicy" :pagination="false"  style="margin-top: 10px;">
				<template #bodyCell="{column, record, index}">
					<template v-if="column.key === 'type'">
						{{ record.versionType === 3 ? '批量定价' : '场次定价' }}
					</template>
					<template v-else-if="column.key === 'isDisabled'">
						<div v-if="record.isDisabled === 0 || record.isDisabled === true">
							启用
						</div>
						<div v-else-if="record.isDisabled === 1 || record.isDisabled === false">
							禁用
						</div>
					</template>
					<template v-if="column.key === 'action'">
						<div style="display: flex;">
							<span>
								<policy :disabled="isSee" :id="modelRef.cinemaId" type="tiktok" v-model:value="modelRef.cardPolicy[index]">
									<slot><a-button type="primary" ghost>修改</a-button></slot>
								</policy>
							</span>
							<a-button :disabled="isSee" style="margin-left: 20px;" type="danger" ghost @click="onDelete(index)">删除</a-button>
						</div>
					</template>
				</template>
			</a-table>

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button v-if="!isSee" type="primary" @click="onSubmit(true)">{{ isEdit ? '提交' : '提交' }}</a-button>
					<!-- <a-button v-if="!isEdit" style="margin-left: 20px;" type="primary" @click="onSubmit(false)">添加并继续</a-button> -->
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>
	</div>
</template>

<script>
	// import dayjs from 'dayjs';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import policy from '@/components/policy/index.vue';
	import snackSelect from '@/components/snackSelect/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { saveCardPolicy, updateCardPolicy, getCardPolicyDetail } from '@/service/modules/coupon.js';
	export default {
		components: {
			Icon,
			Header,
			policy,
			snackSelect
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
			this.getOrganizationList();
			if (this.isEdit && this.id) {
				this.getData();
			}
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				modelRef: {
					tiktokTicketType: 1,
					type: 4,
					cardType: 4,
					isDisabled: false,
					isHaveSnack: 0,
					cardPolicy: []
				},
				snackList: [],
				snackColumns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'image',
					width: 80
				}, {
					title: '卖品分类',
					dataIndex: 'classifyName'
				}, {
					title: '状态',
					key: 'isShelf'
				}],
				columns: [{
					title: '政策名称',
					dataIndex: 'pricingName'
				}, {
					title: '优先级',
					dataIndex: 'pricingLevel'
				}, {
					title: '规则类型',
					key: 'type'
				}, {
					title: '是否启用',
					key: 'isDisabled'
				}, {
					title: '补差金额(元)',
					dataIndex: 'diffPrice'
				}, {
					title: '操作',
					key: 'action'
				}],
				list: [],
				policyModel: {
					versionType: 3,
					isDisabled: true,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					isHoliday: false,
					visualType: 1,
					dimensionalList: [],
					showList: [],
					startDate: null,
					endDate: null,
					timeList: [],
					deductionAmount: ''
				}
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardPolicyDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						if (ret.data.cardPolicy && ret.data.cardPolicy.length) {
							ret.data.cardPolicy.forEach(policyItem => {
								policyItem.isDisabled = policyItem.isDisabled ? false : true;
								policyItem.isHoliday = policyItem.isHoliday ? true : false;
								policyItem.filmList = policyItem.filmList ? policyItem.filmList.split(',') : [];
								policyItem.hallList = policyItem.hallList ? policyItem.hallList.split(',') : [];
								policyItem.showList = policyItem.showList ? JSON.parse(policyItem.showList) : [];
								policyItem.dimensionalList = policyItem.dimensionalList ? policyItem.dimensionalList.split(',').map(item => Number(item)) : [];
								policyItem.startDate = policyItem.startDate ? this.moment(policyItem.startDate *1000) : undefined;
								policyItem.endDate = policyItem.endDate ? this.moment(policyItem.endDate *1000) : undefined;

								policyItem.movieList = policyItem.cinemaFilmList ? policyItem.cinemaFilmList.map(item => {
									item.id = item.filmId.toString();
									item.code = item.filmCode;
									item.shortName = item.film;
									return item;
								}) : [];
								delete policyItem.cinemaFilmList;

								policyItem.hallDataList = policyItem.cinemaHallList ? policyItem.cinemaHallList.map(item => {
									item.id = item.hallId.toString();
									item.name = item.hall;
									item.code = item.hallCode;
									return item;
								}) : [];
								delete policyItem.cinemaHallList;

								if (policyItem.timeList) {
									policyItem.timeList = JSON.parse(policyItem.timeList);
									policyItem.timeList.forEach(item => {
										let startTime = this.moment.duration(Number(item.startTime), 'second').hours() + ':' + this.moment.duration(Number(item.startTime), 'second').minutes();
										let endTime = this.moment.duration(Number(item.endTime), 'second').hours() + ':' + this.moment.duration(Number(item.endTime), 'second').minutes();
										item.week = item.week.split(',').map(subItem => Number(subItem));
										item.startTime =  this.moment(startTime, 'HH:mm');
										item.endTime = this.moment(endTime, 'HH:mm');
									})
								}
							})
							// ret.data.cardPolicy = ret.data.cardPolicy;
						} else {
							ret.data.cardPolicy = [];
						}
						if (ret.data.snackList) {
						  const snackList = ret.data.snackList;
						  if (snackList.length) {
							ret.data.snackIds = ret.data.snackIds.split(',').map(item => Number(item));
							this.snackList = snackList;
						  }
						} else {
							ret.data.snackIds = [];
						}
						ret.data.isDisabled = ret.data.isDisabled ? false : true;
						this.getCinemaList(ret.data.organizationId);
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit(isBack) {
				this.$refs.form.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.isDisabled = postData.isDisabled ? 0 : 1;
					if (postData.cardPolicy.length === 0) {
						return this.$message.warn('请添加线上会员卡使用政策');
					}
					if (postData.isHaveSnack) {
						if (postData.snackIds.length) {
							postData.snackIds = postData.snackIds.join(',');
						} else {
							return this.$message.warn('请选择包含卖品');
						}
					}
					let hasSameLevel = false;
					let levels = [];
					postData.cardPolicy.forEach(item => {
						if (levels.indexOf(item.pricingLevel) !== -1) {
							hasSameLevel = true;
						}
						levels.push(item.pricingLevel);

						if (item.movieList) {
							//  删掉不需要的字段
							delete item.movieList;
						}
						if (item.cinemaHallList) {
							//  删掉不需要的字段
							delete item.cinemaHallList;
						}
						if (item.hallDataList) {
							delete item.hallDataList;
						}
						if (item.versionType === 4 && item.filmFieldIds) {
							item.filmFieldIds = item.filmFieldIds.join(',');
						}
						item.isDisabled = item.isDisabled ? 0 : 1;
						if (item.versionType === 3) {
							item.isHoliday = item.isHoliday ? 1 : 0;
							item.filmList = item.filmList.join(',');
							item.hallList = item.hallList.join(',');
							item.dimensionalList = item.dimensionalList.join(',');
							if (item.startDate) {
								item.startDate = parseInt(this.moment(item.startDate).startOf('day').valueOf() / 1000);
							} else {
								item.startDate = 0;
							}
							if (item.endDate) {
								item.endDate = parseInt(this.moment(item.endDate).endOf('day').valueOf() / 1000);
							} else {
								item.endDate = 0;
							}
							if(item.timeList) {
								item.timeList.forEach(subItem => {
									subItem.week = subItem.week.join(',');
									subItem.startTime = this.moment(subItem.startTime).get('hour') * 60 *60 + this.moment(subItem.startTime).get('minute') * 60;
									subItem.endTime = this.moment(subItem.endTime).get('hour') * 60 *60 + this.moment(subItem.endTime).get('minute') * 60;
								})
							}
							delete item.showList;
						} else {
							delete item.filmFieldIds;
							delete item.isHoliday;
							delete item.movieList;
							delete item.hallDataList;
							delete item.filmType;
							delete item.filmList;
							delete item.hallType;
							delete item.hallList;
							delete item.visualType;
							delete item.dimensionalList;
							delete item.startDate;
							delete item.endDate;
							delete item.timeList;
						}
					});
					if (hasSameLevel) {
						return this.$message.warn('使用政策中含有相同优先级的政策，无法提交');
					}
					try {
						let ret;
						this.loading = true;
						if (this.isEdit && this.id) {
							postData.id = this.id;
							ret = await updateCardPolicy(postData);
						} else {
							ret = await saveCardPolicy(postData)
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							if (isBack) {
								this.onBack(true);
							} else {
								this.cinemaAllList = [];
								this.$refs.form.resetFields();
							}
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onOrganizationChange(id) {
				this.getCinemaList(id);
			},
			filterOption(input, option) {
				return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},
			onPolicyRuleOk(data) {
				this.modelRef.cardPolicy.push(this.$deepClone(data));
				this.policyModel = {
					versionType: 3,
					isDisabled: true,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					isHoliday: false,
					visualType: 1,
					dimensionalList: [],
					showList: [],
					startDate: null,
					endDate: null,
					timeList: [],
					deductionAmount: ''
				};
			},
			onDelete(index) {
				this.$confirm({
					title: '提示',
					content: '确定移除吗？',
					onOk: () => {
						this.modelRef.cardPolicy.splice(index, 1);
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-header {
		padding: 20px 0 10px;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;
		border-bottom: solid 1px #eee;
	}
	.ui-flex {
		display: flex;
	}

	.ui-input {
		width: 180px;
		display: inline-block;
	}

	.ui-margin__top20 {
		margin-top: 20px;
	}

	.ui-margin__top40 {
		margin-top: 40px;
	}

	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #ccc;
	}

	.ui-time__span {
		padding: 0 10px;
	}

	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}
</style>
